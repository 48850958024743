* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f9fafb;
}

a {
  text-decoration: none;
}


.ps__rail-x:hover > .ps__thumb-x, .ps__rail-x:focus > .ps__thumb-x, .ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 9px;
}


.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  height: 9px;
  bottom: 0px;
  position: absolute;
}

.MuiDrawer-paperAnchorDockedLeft{
  border:0 !important;
  background-color: #6A1A5F !important;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.MuiDrawer-paperAnchorLeft{
  background-color:#6A1A5F !important;
}